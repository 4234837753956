<template>
  <div class="auth-content align-center">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="">
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="">
    <div class="audit-content">
      <div class="audit">

        <div class="edit-icon flex-nowrap">已有账号，
          <div @click="quitLogin">
            <span class="blue">快捷登录</span>
          </div>
        </div>
        <div class="seat"></div>
        <el-divider></el-divider>
        <div class="content">
          <div class="title">
            君莲书院隐私政策
          </div>
          <div class="center">
            <p style="text-align: end">最新更新日期：2020年3月31日</p>
            <p>解螺旋(上海)医学科技有限公司（下统称为 “我们”）尊重您的个人隐私。本隐私政策旨在释明我们是谁，我们如何收集、分享和使用您的个人信息，以及您如何维护您的合法权益。</p>
            <p>本隐私政策适用于解螺旋(上海)医学科技有限公司（APP、中台）（以下统称为“平台”）收集的个人信息以及您使用我们的服务（包括但不限于我们的APP、公众号、中台等）时我们所收集的个人信息，比如您向我们申请解螺旋(上海)医学科技有限公司的各项服务时。</p>
            <p><span class="bold">如果您对我们使用您的个人信息有任何疑问或顾虑，请通过本隐私政策底部所示联系方式与我们联系。</span></p>
            <p><span class="bold">我们收集的个人信息以及原因</span></p>
            <p>我们可能收集您的个人信息，这些个人信息会大致分为以下几类：</p>

            <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
            <p>我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
            <p>（一）为您提供网上购课和在线学习服务</p>
            <p>1、业务功能一：注册成为用户</p>
            <p>为完成创建账号，您需提供以下真实信息：您的手机号、电子邮箱地址、创建的用户名和密码。</p>
            <p>你也可以使用第三方账号（如微信）登录进入君莲书院，你此时将授权我们获取你在第三方平台注册的公开信息（头像、昵称等），并在你同意本隐私政权政策后将你的第三方账号与你的君莲书院账号绑定，使你可以通过第三方账号直接登录并使用本产品和相关服务。</p>
            <p>在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：头像、职位、职业证件、学生证件、收货地址。但如果您不提供这些信息，将不会影响使用本服务的基本功能。</p>
            <p>您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您申请注销账号时，我们将停止使用并删除上述信息。</p>
            <p>上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</p>
            <p>2、业务功能二：账号实名认证</p>
            <p>根据《网络安全法》第二十四条第一款之规定，在您开始使用我们的产品（或服务）时，需要提供您的真实身份信息，包括您的姓名、身份证、手机号，以及因无法进行自动识别需要人工审核时，可能需要您提供手持身份证照片以确认您的真实身份。为了确保您注册或使用账号的拥有及使用权是您本人，我们会将您提供的这些信息与您注册或使用的账号进行绑定，以确保您对本账号的拥有及使用权。请您注意为了保障账号始终与您的身份相匹配，一旦您的实名信息认证通过并与您的账号绑定后，账号绑定的实名信息将不能再更改，故而请您在决定提供您的真实身份信息并绑定到您即将注册或使用的账号时进行最终确认。</p>
            <p>3、业务功能三：课程内容展示及其它个性化服务</p>
            <p>为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用君莲书院的浏览、搜索、交易等基本服务，但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：</p>
            <p>（一）基于存储权限的附加服务：我们向您申请获取此权限，目的是为了保障客户端的稳定运行。在您开启我们可读取/写入您设备存储的权限后，我们将在您设备存储空间（包含外置存储卡）内读取或向其中写入图片、文件、崩溃日志信息，用于向您提供信息发布或在本地记录崩溃日志信息等功能。</p>
            <p>（二）基于您内容订阅和算法等决策机制推荐和推送你可能感兴趣的内容。我们可能会收集您在君莲书院上的操作行为并存储为网络日志信息，包括：设备及系统信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备硬件及系统信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符（MAC/IMEI/Android ID/IDFA/IDFV/OPENUDID/IMSI）、设备环境、移动应用列表等软硬件特征信息）、系统参数</p>
            <p>（三）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务，我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符（包括设备型号、操作系统版本、设备设置、唯一设备标识符（MAC/IMEI/Android ID/IDFA/IDFV/OPENUDID/IMSI）、设备环境、移动应用列表等软硬件特征信息）、系统参数</p>
            <p>（四）手机号码数据为个人敏感信息，你有权拒绝提供，这将使你无法学习课程等相关服务。当我们要将信息用于本策略未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>

            <p><span class="bold">您自愿提供的信息</span></p>
            <p>为了使用我们的服务，我们会请您自愿地提供如下个人信息：</p>
            <p>I 联系方式诸如您的姓名、地址、电话号码、电子邮件地址等；</p>
            <p>II 业务需要的证件图片、个人照片等； 为实现身份认证的目的，您同意并授权我们可能以自行或委托第三方向有关身份认证机构（如政府部门等）提供、查询、核对您的前述身份信息。 请注意，您的身份信息、生物识别信息等属于个人敏感信息，请您谨慎提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
            <p>我们使用这些个人信息来为您参加展会提供便利，我们向您收集的个人信息及其原因将在我们向您收集您的个人信息时向您明示。</p>
            <p><span class="bold">我们自动收集的信息</span></p>
            <p>当您访问我们的移动端，我们可能会自动地从您的设备中收集您的特定信息。这些信息会被视为在适用的数据保护法下的个人信息。</p>
            <p>我们自动收集的信息将包含比如您的IP地址、设备型号、唯一设备识别号、浏览器型号、地理位置的信息，以及其他的技术信息。我们也可能收集有关您的设备如何与我们的移动端交互的信息，包括您访问的网页和点击的链接。</p>
            <p>收集这些信息使得我们可以更好的理解访问我们移动端的客人、他们来自何处，以及他们对我们移动端上的何种内容感兴趣。我们将这些信息用于内部分析的目的，以提升我们服务的水平。</p>
            <p>这些信息中的一部分可能使用cookie和类似的追踪技术收集，如本政策所提及的Cookies及类似的追踪技术。</p>
            <p><span class="bold">有关个人敏感信息的提示</span></p>
            <p>以上由您提供或我们收集您的信息中，可能包含您的个人敏感信息，某些个人信息因其特殊性可能被认为是敏感个人信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，例如您的种族、宗教、身份证件号码、个人生物识别信息（包括面部识别特征、声纹等）、社交活动的照片等。
              相比其他个人信息，敏感个人信息受到更加严格的保护。请您谨慎并留意个人敏感信息，您同意我们可以按本政策所述的目的和方式来处理您的个人敏感信息。
            </p>
            <p><span class="bold">第三方插件使用情况</span></p>
            <p>关键术语定义：设备信息：设备标识符（IMEI、IDFA、Android ID、MAC、OAID等相关信息）应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）网络信息：IP地址，WiFi信息，基站信息等相关信息。</p>
            <p>单独的某一设备信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合识别特定自然人身份，或将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除非取得您的授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。</p>
            <p>为了给您提供更专业、安全的核心业务功能和附加业务功能，我们的产品中会接入第三方插件产品（包括在我们的网页或应用程序中部署统计分析工具、软件开发工具包SDK、调用地图API接口）。</p>

            <p><span class="bold">目前我们Android/Ios系统产品中存在的第三方插件披露如下：</span></p>
            <p>微信SDK（tencent.wechat）收集您的网络状态信息，以根据您的指令实现账号的登录、以及资讯信息的分享、微信支付功能；
              深圳市腾讯计算机系统有限公司
              https://open.weixin.qq.com/
            </p>
            <p>极光推送（jPush）收集您的设备信息、网络信息、应用信息以及设备参数以及系统信息，为您提供精准的推送服务以及政策提醒
              深圳市和讯华谷信息技术有限公司
              https://www.jiguang.cn/license/privacy
            </p>
            <p>腾讯直播SDK  IM SDK 收集您的设备生产商、设备型号、系统版本、网络类型、屏幕分辨率、CPU 信息、IP 地址等信息。
              腾讯云移动直播SDK文档
              https://cloud.tencent.com/document/product/454/61839
            </p>

            <p><span class="bold">手机系统权限使用规则</span></p>
            <p>在您使用君莲书院的过程中，我们访问您的各项权限是为了向您提供服务、优化我们的服务以及保障您的帐号安全，具体使用规则如下：</p>
            <p>1、为了提供包括但不限于如下功能，我们需要访问您的<span class="bold">相机</span>权限：</p>
            <p>修改个人头像，相机拍照</p>
            <p>2、为了提供包括但不限于如下功能，我们需要访问您的读写<span class="bold">手机存储</span>：</p>
            <p>修改个人头像，设备中照片或文件中选取</p>
            <p>我们会采用符合业界标准的安全防护措施来保护您的个人信息安全，避免遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们努力使用各种合理的制度、技术、程序以及物理层面的措施来保护您的个人信息，包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。</p>
            <p>您可以通过系统设置内关于QQ的应用权限设置，逐项查看您上述个人信息的访问权限开启状态，并可以决定开启或关闭这些权限。开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，关闭这些权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
            <p><span class="bold">我们从第三方处获得的信息</span></p>
            <p>我们可能会不时地从第三方来源收到有关您的个人信息，但这只发生在：我们确认这些第三方已经获得您的同意，或者第三方依法被许可或被要求向我们披露您的个人信息。</p>
            <p>我们从第三方处收集的信息涵盖了专业展览机构和其他展会协助方提供的信息。我们将这些从第三方处收集的信息用于为您提供服务，以及用于保证我们所掌握的您的相关记录的准确性。</p>
            <p>一般而言，我们仅会将收集到的您的个人信息用于本隐私政策中所描述的目的，或用于我们在收集您的个人信息时向您解释的目的。然而，如果当地适用的数据保护法允许，我们也可能将您的个人信息用于与我们告知您的目的所不同的其他目的（例如：为了公共利益目的、科学或历史研究之目的或者统计目的等）。</p>
            <p><span class="bold">我们与谁分享您的个人信息？</span></p>
            <p>我们可能会将您的个人信息披露给以下类别的主体：</p>
            <p>第三方服务提供商以及为我们提供数据处理服务的合作方（例如：为移动端服务提供技术支持的专业机构），或按本隐私政策所描述的目的，或我们在收集您的个人信息时通知您的用途，以其他方式处理个人信息的合作方。</p>
            <p>我们认为基于以下原因披露您的个人信息是必要的：</p>
            <p>I为适用法律；</p>
            <p>II实施、建立、维护我们的合法权利；</p>
            <p>III保护您或其他人的切身利益，向任何主管的执法机构、监管机构、政府机构、法院或其他第三方披露。</p>
            <p><span class="bold">在征得您的同意后向其他任何人披露</span></p>
            <p>然而，我们通常只会在下列情况下收集您的个人信息：</p>
            <p>I我们履行与您合同时所需的您的个人信息；</p>
            <p>II处理过程符合我们的合法利益且您在此之上没有其他在先权利；</p>
            <p>III我们已经征得您的同意。</p>
            <p>在某些情况下，我们也可能有义务去收集您的个人信息，或者可能需要这些个人信息来保护您或其他人的切身利益。</p>
            <p>如果我们以合规之目的或以履行与您之间的合同为目的，要求您提供个人信息，我们将在相关时间内明确说明并告知您是否在强制要求下提供个人信息（以及如果您不提供个人信息可能导致的后果）。</p>
            <p>如果我们因我们自身的合法利益（或任何第三方的合法利益）而收集或使用您的个人信息，此利益通常是为了：运营我们的展会以及我们在必要时与您沟通以向您提供我们的服务，以及为实现我们合法的商业利益，例如：回复您的问题、改进我们的服务或检查、防范违法行为之目的。此外我们可能还有其他的合法利益。 </p>
            <p>如果您对我们收集和使用个人信息的法律依据有任何疑义或需要进一步的信息，请按“客服”标题下的详细的联系方式与我们联系。</p>
            <p>Cookies及类似的追踪技术</p>
            <p>我们使用cookies和类似的追踪技术（统称为“Cookies”）来收集和使用您的个人信息，包括：为您提供您感兴趣的广告。关于我们使用的Cookie类型、为什么使用Cookies以及如何控制Cookies的更多信息，请参阅我们的Cookies政策介绍。</p>
            <p><span class="bold">我们如何保障您的个人信息安全</span></p>
            <p>我们使用适合的技术手段和组织措施来保障我们所收集的您的个人信息。我们采取的措施旨在为您提供与处理您的个人信息产生的风险相匹配的安全保障级别。</p>
            <p><span class="bold">数据存储</span></p>
            <p>我们基于持续合法的经营需求（如向您提供您所需的服务或遵守适用的法律要求），我们会存储从您处收集的个人信息。</p>
            <p>如果我们不因持续合法的经营需求来处理您的个人信息，我们将删除或将其匿名化，如果不能采取这类措施（比如，因您的个人信息已存储在备份存档中），则我们将安全地存储您的个人信息并在能够删除此类信息前避免对其做进一步的处理。</p>
            <p><span class="bold">您的数据保护权利</span></p>
            <p>您享有以下数据保护的权利：</p>
            <p>如果您有意访问、纠正、更新或要求删除您的个人信息，您可以随时通过“客服”标题下所提供的详细联系方式来联系我们。</p>
            <p>此外，您有权拒绝我们处理您的个人信息，有权要求我们限制处理您的个人信息或请求携带您的个人数据。再次说明，您可以通过“客服”标题下所提供的详细联系方式来联系我们，以行使这些权利。</p>
            <p>同样，如果我们在征得您的同意前提下收集并处理了您的个人信息，您有权随时撤回您的同意。您撤回同意不会影响我们此前的任何处理行为的合法性，也不会影响依据法定理由而非基于您的同意而处理您的个人信息的合法性。</p>
            <p>我们会根据适用的数据保护法律来回应我们所收到的要求行使数据保护权的主体的所有请求。</p>
            <p><span class="bold">本隐私政策的更新</span></p>
            <p>我们可能适时地更新本隐私政策以适应法律、技术或商业的发展。我们更新本隐私政策时，我们将以适当的方式通知您。若适用的数据保护法律要求我们在实质修订隐私政策时征得您的同意，我们将遵守法律的规定。您可以在本隐私政策的顶部的“最新更新”的日期处查看本隐私政策的最新更新日期。</p>
            <p><span class="bold">如何联系我们</span></p>
            <p>如果您对我们使用您的个人信息有任何疑问或疑虑，请使用以下详细信息联系我们：</p>
            <p>电邮：service@helixlife.com.cn</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{$static.footer_text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Perfect",
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    quitLogin() {
      this.$router.push('/login')
    }
  },
}
</script>

<style lang="less" scoped>
@import "~assets/less/auth/auth.less";
@import "~assets/less/auth/audit.less";
</style>

